header {
    background-color: #161E2D !important;
}

.logo-link {
    font-size: 24px;
}

.notification {
    background-image: url('img/alert.svg'); 
}

.help {
    background-image: url('img/question-circle.svg');
}

.site-navigation {
    width: 100%;
    align-items: center;
}

.search-box{
    margin-top: 10px !important; 
}