body,
html,
main,
#app {
  height: 100%;
}

.site-navigation {
  display: flex;
}

.header-link {
  font-size: 14px;
  text-align: center;
}

.icon {
  background-repeat: no-repeat;
  background-position: center;
}

a:hover {
  text-decoration: none !important;
}
