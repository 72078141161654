a.catalog-item-card {
 
    font-weight: inherit;

    h4 {
        margin: auto;
    }
    
    .card-horizontal {
        display: flex;
        flex: 1 1 auto;
    }

    .img-square-wrapper {
        padding-right: 25px;
    }

    img {
        max-height: 70px;
        max-width: 70px;
    }
}