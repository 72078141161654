.recommended-items-container {
    margin: 0 0 32px 0 !important;
}

.hero-container {
    background: url('img/hero-background.svg') right 58%; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}