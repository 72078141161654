.search-results-container {
  height: calc(100% - 116px);
}

.search-results-column {
  overflow: auto;
  width: 100%;
}

.cfm-no-scroll {
  right: 2px;
}

.cfm-scroll {
  right: 20px;
}

.cfm-general {
  height: 96px;
  width: 196px;
}
