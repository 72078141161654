.recommended-items {
    h2 {
        margin: 32px 0 24px 0;
    }
}

.recommended-items-by-3 {
    .recommended-item {
        width: 33% !important;
    }
}

.recommended-items-by-4 {
    .recommended-item {
        width: 25% !important;
    }
}