@import "../../styles/colors.scss";

.product-details {

    background-color: $neutral05;

    .product-title {
        font-weight: 700;
        font-size: 32px;
        color: $base-text-color;
    }

    .product-description {
        font-weight: 400;
        font-size: 16px;
        color: $base-text-color;
    }

    .product-description-box {
        width: 100%;
        top: 128px;
        background: $neutral05;
    }

    .product-img {
        border-radius: 24px;
        max-height: 152px;
        max-width: 152px;
    }

    .product-short-details {
        padding-top: 15px !important;

        
    }

    .about-product {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #56616C !important;
    }

    .about-product-view {
        padding-top: 24px !important;
    }

    .details-description {
        text-overflow: ellipsis;
        padding-top: 20px;
    }

    hr {
        color: $neutral_neutral20;
        margin: 24px 0;
    }
    
    .product-property {
        label {
            color: $neutral_neutral70;
            font-size: 16px;
        }
    }
}

.column-left {
    flex: 2;
}

.column-center {
    flex: 8;
}

.column-right {
    flex: 2;
    text-align: right;
}