@import "../../styles/colors.scss";

.no-results-details {

    background-color: $neutral05 !important;
    background: $neutral05 !important;

    .text{
        color: "neutral70";
    }

}