.image-circle {
    width: 32px;
    height: 32px;

    background: #F4F6F6;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #0E6661;
    border-radius: 100px;
}

@media only screen and (max-width: 600px) {
  .username {
    display: none;
  }
}